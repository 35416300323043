import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "send"
    }}>{`Send`}</h2>
    <p>{`To keep it as simple as possible we accept a GET request.`}</p>
    <h3 {...{
      "id": "http-endpoint"
    }}>{`HTTP Endpoint`}</h3>
    <p>{`You need to send your HTTP requests to `}<a parentName="p" {...{
        "href": "https://api.anymessage.cloud/call/initiate"
      }}>{`https://api.anymessage.cloud/call/initiate`}</a></p>
    <h3 {...{
      "id": "get-parameters"
    }}>{`GET parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`mandatory`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`sample value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`username`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`anyDir001`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`password`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Provided from AnyMessage to authenticate yourself`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`hz$o932`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sender`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The sender of the call. Provided in international format without leading + or 00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`491520987654`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`recipient`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The call recipient. Provided in international format without leading + or 00`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`491520123456`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`content`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`yes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`A comma separated list of previous uploaded audio files`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`myGreetingAudio,myMainContentAudio,myGoodbyeAudio`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "encoding"
    }}>{`Encoding`}</h3>
    <p>{`The request (and request parameters) needs to be in UTF-8 and url encoded.`}</p>
    <h3 {...{
      "id": "request-examples"
    }}>{`Request examples`}</h3>
    <p>{`The following examples fulfill this scenario: `}</p>
    <ol>
      <li parentName="ol">{`You want to send a call to your german friend. His number equals 0049 1520 12345678`}</li>
      <li parentName="ol">{`You want your own phone number as call source address. Your number is 0049 1520 987654`}</li>
      <li parentName="ol">{`The call should playback two previous uploaded audio files.`}<ol parentName="li">
          <li parentName="ol">{`First one is myIntroductionFile`}</li>
          <li parentName="ol">{`Second one is myOfferFile`}</li>
        </ol></li>
      <li parentName="ol">{`AnyMessage provided you the following credentials username = anyDir001 and password = hz7r§j`}</li>
    </ol>
    <h4 {...{
      "id": "shell"
    }}>{`Shell`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`
curl -G -i \\
    --data-urlencode "username=anyDir001" \\
    --data-urlencode "password=hz7r§j" \\
    --data-urlencode "sender=491520987654" \\
    --data-urlencode "recipient=49152012345678" \\
    --data-urlencode "content=myIntroductionFile,myOfferFile" \\
    https://api.anymessage.cloud/call/initiate
`}</code></pre>
    <p>{`Please note`}</p>
    <ul>
      <li parentName="ul">{`The recipient is 49152012345678. The leading 00 is removed`}</li>
      <li parentName="ul">{`The recipient is 491520987654. The leading 00 is removed`}</li>
    </ul>
    <h4 {...{
      "id": "php"
    }}>{`PHP`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-php"
      }}>{`
<?php

$url = 'https://api.anymessage.cloud/call/initiate?';

$username = 'anyDir001'; 
$password = 'hz7r§j'; 
$sender = '491520987654'; // NOTE the leading 00 of sender is truncated
$recipient = '49152012345678'; // NOTE the leading 00 of recipient is truncated
$content = 'myIntroductionFile,myOfferFile';   


$parameter = array(
    'username'=>$username,
    'password'=>$password,
    'sender'=>$sender,
    'recipient'=>$recipient,
    'content'=>$content,
);

$url .= http_build_query($parameter);
    

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_HEADER, 0);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);

$response = curl_exec($ch);

// Response evaluation 
if($response === false){
    var_dump(curl_error($ch));
}else{
    var_dump($response);
}
?>
`}</code></pre>
    <h3 {...{
      "id": "request-responses"
    }}>{`Request responses`}</h3>
    <h4 {...{
      "id": "on-success"
    }}>{`On success`}</h4>
    <p>{`You Receive a HTTP status code of 200 and a JSON formatted message containing a message ID.`}</p>
    <p>{`A sample success response looks like this.`}</p>
    <pre><code parentName="pre" {...{}}>{`> {"msgId":"call_a8064627-8e54-4928-9574-53ff4ea11ccc"}
`}</code></pre>
    <h4 {...{
      "id": "on-failure"
    }}>{`On failure`}</h4>
    <pre><code parentName="pre" {...{}}>{`| HTTP status Code | Http response                                    | description                                               |
| ---------------- | ------------------------------------------------ | --------------------------------------------------------- |
| 400              | {"message":"Not all params specified!"}          | The request does not contain all mandatory GET parameters |
| 400              | {"message":"sender needs to be numeric only"}    | The provided sender contains non digit characters         |
| 400              | {"message":"recipient needs to be numeric only"} | The provided recipient contains non digit characters      |
| 400              | {"message":"content file XYZ does not exist"}    | the content file with name XYZ does not exist             |
| 401              | {"message":"Invalid credentials"}                | The provided credentials are not valid                    |
| 403              | {"message":"Action forbidden"}                   | Your account is not authorized to use this endpoint       |
| 500              | {"message":"Internal Error"}                     | An internal error occurred                                |
`}</code></pre>
    <h2 {...{
      "id": "dlr"
    }}>{`DLR`}</h2>
    <p>{`Please note that this API is presently not publicly available, as the current implementation is proprietary or customer specific.`}</p>
    <p>{`If you would like to use this API, please don't hesitate to contact us `}<a parentName="p" {...{
        "href": "mailto:contact@anymessage.cloud?subject=API%20Documentation%20request%20Call%20DLR&body=Dear%20AnyMessage%20Team%2C%0AI%20have%20read%20your%20online%20API%20Documentation%20and%20am%20intested%20in%20the%20following%20services%3A%0A%20%0A%20Call%20DLR%20API.%0A%20%0AYou%20can%20reach%20us%20via%20the%20following%3A%0A%20Mail%3A%20%22the%20one%20used%20here%22%20%0A%20Alternative%20E-Mail%3A%20%0A%20Phone%3A%0A%20%0AThank%20you%20in%20advance%20for%20getting%20back%20to%20us%3A%0A%20%0ANAME"
      }}>{`“here”`}</a>{`.`}</p>
    <p>{`Thank you for your understanding - We look forward to talking to you!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      